<template lang="pug">
div#app
  div#header(v-if="false")
    comp-header
  div#content(v-if="isConfigLoaded")
    div#left.column(v-if="false")
      comp-screen-share
    div#center
      comp-rtmp-info(
        :OBSServer='OBSServer',
        :OBSCode='OBSCode',
        :expirationTime='expirationTime'
        v-if="isConfigLoaded"
      )
      comp-live-stream(v-if="false")
    div#right.column
      div.logo
        img(src="https://wanwuxiu-shop-test2.oss-cn-qingdao.aliyuncs.com/attach/2023/09/49513202309161817011832.png")
      comp-live-setting
      comp-participants
      comp-chat
<!--  room-device-dialog(ref="roomDeviceDialog" @nextStep="showBeautyPresettingDialog" v-if="isConfigLoaded")-->
<!--  room-beauty-dialog(ref="roomBeautyDialog" v-if="isConfigLoaded")-->
</template>

<script>
// import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import {
  SET_APP_INFO,
  SET_ROOM_ID,
  UPDATE_ROOM_NAME,
  UPDATE_USER_INFO,
} from 'constants/mutation-types';
// import {
//   sdkAppId,
//   expireTime,
//   secretKey,
//   anchorUserInfo,
//   roomInfo,
// } from '@/config/basic-info-config';
import compHeader from '@/components/comp-header';
import compRtmpInfo from '@/components/comp-rtmp-info';
import compScreenShare from '@/components/comp-screen-share';
import compLiveSetting from '@/components/comp-live-setting/index.vue';
import compLiveStream from '@/components/comp-live-stream';
import compParticipants from '@/components/comp-participants';
import compChat from '@/components/comp-chat';
import roomDeviceDialog from '@/components/comp-pre-setting/room-device-dialog.vue';
import roomBeautyDialog from '@/components/comp-pre-setting/room-beauty-dialog.vue';
import { getLiveStudioInfo } from './api/liveStudio';
export default {
  name: 'App',
  data() {
    return {
      isConfigLoaded: false,
      OBSServer: null,
      OBSCode: null,
      expirationTime: null,
  };
  },
  components: {
    compHeader,
    compRtmpInfo,
    compScreenShare,
    compLiveSetting,
    compLiveStream,
    compParticipants,
    compChat,
    roomBeautyDialog,
    roomDeviceDialog,
  },
  methods: {
    // 显示设置预设置弹窗
    showDevicePresettingDialog() {
      this.$refs.roomDeviceDialog.handleShowDeviceDialog();
    },
    // 显示美颜预设置弹窗
    showBeautyPresettingDialog() {
      this.$refs.roomBeautyDialog.handleShowBeautyDialog();
    },
    // 设置TUIPusher配置信息
    async handlePusherInfo() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('room_id')) {
        this.room_id = urlParams.get('room_id');
      }
      const params = { room_id: urlParams.get('room_id') };
      await getLiveStudioInfo(params).then(({ data }) => {
        console.log(data);
        const sdkAppId = data.sdkAppId;
        const userSig = data.userSig;
        const roomInfo = data.roomInfo;
        const anchorUserInfo = data.anchorUserInfo;
        console.log(roomInfo);
        this.$store.commit(SET_APP_INFO, {
          sdkAppId,
          userSig,
          shareUserSig: userSig,
        });
        this.$store.commit(SET_ROOM_ID, roomInfo.roomId);
        this.$store.commit(UPDATE_ROOM_NAME, roomInfo.roomName);
        this.$store.commit(UPDATE_USER_INFO, {
          userId: anchorUserInfo.userId,
          userName: anchorUserInfo.userName,
          userAvatar: anchorUserInfo.userAvatar,
        });
        this.OBSServer = data.roomPushStreamUrl.OBSServer;
        this.OBSCode = data.roomPushStreamUrl.OBSCode;
        this.expirationTime = data.roomPushStreamUrl.expirationTime;
        this.isConfigLoaded = true;
        // this.showDevicePresettingDialog();
      }).catch((res) => {
        console.log('失败', res);
        if (res.data.code === 4001) {
          // 错误码4001为用户未登录，记录下回跳地址并跳转到登录页
          localStorage.setItem('login_back_url', window.location.href);
          console.log('跳转到登录页');
          window.location.assign('/merchant/login/index.html');
          return false;
        }
        this.$message.error(res.data.msg);
      });
    },
    // 退出直播间
    async handleExit() {
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
    },
  },
  async created() {
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
  },
  mounted() {
    this.handlePusherInfo();
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
  },
};
</script>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';
#app
  div.logo
    height 50px
    img
      height 40px
  font-family Avenir, Helvetica, Arial, sans-serif
  --webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  width 100%
  height 100%
  position relative
  color $fontColor
  div#header
    width 100%
    height 50px
    background-color $backgroundColor
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
  div#content
    position absolute
    top 0px
    width 100%
    left 0
    bottom 0
    display flex
    background-color $backgroundColor
    div#left
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color $themeColor
    div#center
      height 100%
      flex-grow 1
    div#right
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color #454545
    .column
      padding 8px
      display flex
      flex-direction column
      > div:not(:first-child)
        margin-top 8px
      > div:last-child
        flex-grow 1
</style>
