<!--
 * @Description: 视频流区域
 * @Date: 2021-09-22 16:40:01
 * @LastEditTime: 2021-11-01 15:32:38
-->
<template>
  <div id="rtmpInfo">
    <div v-if="!isShow">
      <el-button @click="isShow = true" type="danger" icon="el-icon-view" >显示推流码</el-button>
    </div>
    <div class="rtmp-input-grp" v-else>
      <div class="alert">
            <small> 剩余时间 </small>
            <span class="countdown"> {{ days }} 天 {{ hours }} 小时 {{ minutes }} 分 {{ seconds }} 秒 </span>
            <small> 刷新页面后生成新码 </small>
      </div>
      <div style="margin-top: 15px;">
        <el-input v-model="OBSServer">
          <template slot="prepend">服务器</template>
          <el-button  @click="copy('服务器',OBSServer)" slot="append" icon="el-icon-document-copy"></el-button>
        </el-input>
      </div>
      <div style="margin-top: 15px;">
        <el-input v-model="OBSCode">
          <template slot="prepend">推流码</template>
          <el-button  @click="copy('推流码',OBSCode)" slot="append" icon="el-icon-document-copy"></el-button>
        </el-input>
      </div>
      <div style="margin-top: 15px;">
        <el-button @click="isShow = false" icon="el-icon-remove-outline" plain >隐藏推流码</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'compRtmpInfo',
  components: {
  },
  props: {
    OBSServer: String,
    OBSCode: String,
    expirationTime: String,
  },
  data() {
    return {
      isShow: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  methods: {
    startCountdown() {
      const update = () => {
        const now = new Date().getTime();
        const distance = new Date(this.expirationTime).getTime() - now;

        if (distance <= 0) {
          return;
        }

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeout(update, 1000);
      };

      update();
    },
    async copy(label, val) {
      try {
        await navigator.clipboard.writeText(val);
        console.log("复制成功");
        this.$message.success(`${label}复制成功`);
      } catch (err) {
        console.error("复制失败", err);
        this.$message.error("${label}复制失败");
      }
    }
  },
  mounted() {
    this.startCountdown();
  },
};
</script>

<style>

#rtmpInfo {
  background-color: #333;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown {color: #ff3300}

.rtmp-input-grp {
  min-width: 500px;

  max-width: 700px;

}

</style>

