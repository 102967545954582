/* eslint-disable camelcase */
import request from './request';

/**
 * @description 获取直播信息
 */
// eslint-disable-next-line camelcase
export function getLiveStudioInfo(data) {
  return request.get('live_studio.live_room/get_live_info', data);
}

/**
 * @description 发送系统消息
 */
export function sendGrpNotification(group_id, content) {
    return request.post('live_studio.live_room/send_grp_notification', { group_id, content });
}
