<template lang="pug">
  div.icon-button-container(@click="handleClick")
    div.icon(v-if="false")
      slot
    div.text
      span {{text}}
</template>

<script>
export default {
  name: 'icon-button',
  props: {
    text: String,
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="stylus" scoped>
.icon-button-container
  width 25%
  height 30px
  cursor pointer
  .icon
    color $fontColor
    svg
      width 18px
      height 18px
  .text
    color $fontColor
    margin-top 0px
    font-size 0.8rem
</style>
