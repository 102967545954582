<template lang="pug">
item-card.card-container(:title="$t('Chat')")
  template
    div.content-play
        div.content-play-button
          //- el-input.grp_input(
          //-   type="text"
          //-   v-model="inputLinkText"
          //- )
          input.grp_input(
            type="text"
            placeholder='群链接地址'
            v-model="inputLinkUrl"
          )
          div.button_grp
            el-button(size="mini"  @click="handleSendPopMsg") 发布群链接
            el-button(size="mini"  @click="handleHiddenPopMsg") 隐藏
    div.divider

    //- 聊天区域
    div.content-top-chat(ref="box")
      div.out(v-if="messageList.length === 0") {{ $t('Chat now') }}
      div.single-message(v-for="message, index in messageList")
        div.message-info
          span.user-name {{ getUserNick(message) }}
          span.message-time {{getMessageTime(message)}}
        div.message-content(@click="chooseMessage(message)")
          span(v-for="item, index in message.renderContent")
            //- 文字消息
            span(
              v-if="item.name === 'text'"
              :key="index"
            ) {{item.content }}
            //- 表情消息
            img.message-icon(
              v-else-if="item.name === 'img'"
              :src="item.src"
              :key="index"
            )

    //- 文字及表情输入区域
    div.content-bottom
      //- 表情选择
      //- div.content-bottom-feel
      //-   el-popover(
      //-     placement='top'
      //-     width='400'
      //-     trigger='click'
      //-     v-model='popoverVisible'
      //-   )
      //-     div.emojis
      //-       div.emoji(
      //-         v-for="emoji in emojiName"
      //-         :key="emoji"
      //-         @click="chooseEmoji(emoji)"
      //-       )
      //-         img(:src="emojiUrl + emojiMap[emoji]")
      //-     span.icon-button(slot="reference")
      //-       svg-icon.emoji-icon(icon-name="emoji")
      //- 文字输入
      div.content-bottom-input
        input.input(
          ref="input"
          type="text"
          v-model="inputMsg"
          :placeholder="$t('Type a message')"
          @keyup.enter="handleSendMsg"
          :disabled="isLiveEnded"
          required="required"
        )
        span.send-button(@click="handleSendMsg") {{ $t('Send') }}
  el-dialog.dialog-style-title(
    title="提示"
    :visible.sync="dialogVisible"
    width="600px"
    :before-close="handleDialogClose"
    class="live-setting-dialog"
  )
    span 消息内容：{{singleMessage.content}}
    p 你是否要禁言此用户？
    span.dialog-footer(slot="footer" )
      el-button(@click="handleDialogClose") 取 消
      el-button(type="primary" @click="muteUser(singleMessage.userID)") 确 定

</template>

<script>
import { sendGrpNotification } from '@/api/liveStudio';
import { Notification } from 'element-ui';
import itemCard from '@/components/common/item-card';
import tim from '@/components/mixin/tim';
import { emojiMap, emojiName, emojiUrl } from '@/utils/emojiMap';
import { LIVE_STAGE } from 'constants/room';
import { mapState } from 'vuex';
import {UPDATE_MEMBER_LIST, SET_ONLINE_COUNT} from '@/constants/mutation-types';
export default {
  name: 'compChat',
  mixins: [tim],
  data() {
    return {
      inputMsg: '',
      inputLinkUrl: '',
      inputLinkText: '',
      rec: '',
      popoverVisible: false,
      emojiMap,
      emojiName,
      emojiUrl,
      dialogVisible: false,
      singleMessage: {},
    };
  },
  components: {
    itemCard,
  },
  computed: {
    ...mapState({
      liveStage: 'liveStage',
      userInfo: 'userInfo',
      userId: state => state.userInfo.userId,
      sdkAppId: state => state.appInfo.sdkAppId,
      userSig: state => state.appInfo.userSig,
    }),
    isLiveEnded() {
      return this.liveStage === LIVE_STAGE.ENDED;
    },
    userData() {
      return {
        sdkAppId: this.sdkAppId,
        userSig: this.userSig,
        userId: this.userId,
      };
    },
  },
  watch: {
    userData: {
      immediate: true,
      handler(val) {
        if (val.sdkAppId && val.userSig && val.userId) {
          this.initTim();
        }
      },
    },
    // 发出一条新消息，自动到最底部
    messageList() {
      console.log('messageList change', this.messageList);
      this.$nextTick(() => {
        const msg =  this.$refs.box;
        msg.scrollTop = msg.scrollHeight;
      });
    },
    // 直播结束之后解散群聊
    liveStage(val) {
      if (val === LIVE_STAGE.ENDED) {
        this.destroyChat();
      }
    },
    memberList: {
      immediate: true,
      handler(val) {
        this.$store.commit(UPDATE_MEMBER_LIST, val);
      },
    },
  },
  methods: {
    // 获取用户昵称
    getUserNick({ nick, userID }) {
      return nick ? nick : userID;
    },
    getMessageTime({ time }) {
      let hour = new Date(time * 1000).getHours();
      let minute = new Date(time * 1000).getMinutes();
      hour = hour >= 10 ? hour.toString() : `0${hour}`;
      minute = minute >= 10 ? minute.toString() : `0${minute}`;
      return `${hour}:${minute}`;
    },
    handleHiddenPopMsg() {
      const content = {
        operationType: 'addGroupLinkPop',
        contentBody: {
          expireDate: 1713943002,
          url: null,
          text: null,
        },
      };
      sendGrpNotification(this.groupID, content).then(({ data }) => {
        Notification({
          title: '群链接已隐藏',
          type: 'warning',
          // message: h('i', { style: 'color: teal'}, this.inputLinkUrl)
        });
        console.log(data);
      })
        .catch((res) => {
          this.$message.error(res.message);
          console.log('失败', res.message);
        });
    },
    // 发送系统消息
    handleSendPopMsg() {
      if (!this.inputLinkUrl) {
        // const h = this.$createElement;
        Notification({
          title: '发布失败,请检查群链接填写是否正确',
          type: 'error',
        });
        // this.$message.error("发布失败,请检查群链接填写是否正确");
        return;
      }
      const content = {
        operationType: 'addGroupLinkPop',
        contentBody: {
          expireDate: 1713943002,
          url: this.inputLinkUrl,
          text: '加群有礼',
        },
      };

      sendGrpNotification(this.groupID, content).then(({ data }) => {
        // const h = this.$createElement;
        Notification({
          title: '已发布群链接',
          type: 'success',
          // message: h('i', { style: 'color: teal'}, this.inputLinkUrl)
        });
        // this.$message.success("已发布群链接");
        console.log(data);
      })
        .catch((res) => {
          this.$message.error(res.message);
          console.log('失败', res.message);
        });
    },
    // 发送消息
    handleSendMsg() {
      if (this.inputMsg === '' || /^\s+$/gi.test(this.inputMsg)) {
        return;
      }
      this.sendMessage(this.inputMsg);
      this.inputMsg = '';
      this.popoverVisible = false;
    },
    // 选择表情
    chooseEmoji(item) {
      this.inputMsg += item;
      this.$refs.input.focus();
    },
    // 销毁群聊
    destroyChat() {
      this.dismissGroup(this.groupID);
      this.logout();
      // 清除对禁言用户ID的记录
      localStorage.removeItem(this.muteUserIdKey);
    },
    handleDialogClose() {
      console.log('关闭弹窗');
      this.dialogVisible = false;
    },
    chooseMessage(message) {
      console.log('选中的消息', message);
      this.dialogVisible = true;
      this.singleMessage = message;
    },
    muteUser(userId) {
      this.$eventBus.$emit('tim:setGroupMemberMuteTime', {
        userID: userId,
        muteTime: 7 * 24 * 60 * 60,
      });
      this.dialogVisible = false;
      this.$message.success('禁言成功');
    },
    async getOnlineCount() {
      const roomId = this.roomId.toString();
      const promise = this.getGroupOnlineMemberCount(roomId);
      promise.then((imResponse) => {
        this.$store.commit(SET_ONLINE_COUNT, imResponse.data.memberCount);
        console.log('直播间在线报文', imResponse);
        console.log('直播间当前人数', imResponse.data.memberCount);
        console.log('直播间ID', this.roomId);
      }).catch((imError) => {
        console.warn('直播间当前人数获取错误getGroupOnlineMemberCount error:', imError); // 获取群在线人数失败的相关信息
      });
    },
    async getOnlineCountInterval() {
      setInterval(() => {
        this.getOnlineCount();
      }, 5000); // 每隔5秒执行一次
    },
  },
  mounted() {
    this.$eventBus.$on('tim:setGroupMemberMuteTime', this.setGroupMemberMuteTime);
    this.$eventBus.$on('tim:setGroupMuteAllMembers', this.setGroupMuteAllMembers);
    this.$eventBus.$on('tim:getGroupProfile', this.getGroupProfile);
    this.$eventBus.$on('timReady', this.getOnlineCount);
    this.$eventBus.$on('timReady', this.getOnlineCountInterval);
  },
  beforeDestroy() {
    this.destroyChat();
    this.$eventBus.$off('tim:setGroupMemberMuteTime', this.setGroupMemberMuteTime);
    this.$eventBus.$off('tim:setGroupMuteAllMembers', this.setGroupMuteAllMembers);
    this.$eventBus.$off('tim:getGroupProfile', this.getGroupProfile);
    this.$eventBus.$off('timReady', this.getOnlineCount);
    this.$eventBus.$off('timReady', this.getOnlineCountInterval);
  },
};
</script>

<style lang="stylus" scoped>
.button_grp
  display flex
  justify-content space-between
.grp_input
  margin-bottom 10px
  background-color #222 !important
  color $fontColor
  border-radius 5px
  top 0
  right 0
  width 100%
  height 42px
  padding-left 13px
  padding-right 70px
  background-color #222
  border none
  outline none
.card-container
  border-top 1px solid #444
  border-radius 0px
  width 100%
  height 64%
  display flex
  flex-direction column
.content-top-chat
  flex-grow 1
  height 330px
  overflow auto
  margin 10px 0px
  border-radius 10px
  padding 14px 14px
  color $fontColor
  font-size 14px
  .out
    color #999
    margin 50% auto
  .single-message
    width 100%
    text-align left
    .message-info
      height 30px
      line-height 30px
      color $grayFontColor
      font-size 14px
      .user-name
        padding-right 12px
    .message-content
      width 80%
      min-width 260px
      background-color rgba(223,223,223,0.05);
      padding 8px 12px
      border-radius 4px
      font-size 16px
      font-weight 500
      word-break break-all
      span
        display inline-block
        vertical-align center
        .message-icon
          width 20px
          height 20px
          vertical-align middle
.divider
  width 100%
  height 2px
  background-color $lineColor

.content-bottom
  width 100%
  padding 14px
  div.content-bottom-feel
    width 100%
    text-align left
    .icon-button
      cursor pointer
      .emoji-icon
        width 24px
        height 24px
      svg
        fill $fontColor
    .smile-icon
      display inline-block
      width 30px
      height 30px
  div.content-bottom-input
    text-align left
    position relative
    margin 4px auto 0
    .input
      color $fontColor
      border-radius 5px
      top 0
      right 0
      width 100%
      height 42px
      padding-left 13px
      padding-right 70px
      background-color #222
      border none
      outline none
    .send-button
      height 42px
      line-height 42px
      position absolute
      right 12px
      cursor pointer
      color $fontColor
      font-size 14px
.content-play
  padding 14px
.emojis
  height 170px
  overflow scroll
  .emoji
    height 30px
    width 30px
    float left
    box-sizing border-box
    img
      width 30px
      height 30px
</style>

<i18n>
{
	"en": {
		"Chat": "Chat",
    "Chat now": "Chat now",
    "Type a message": "Type a message",
    "Send": "Send"
	},
	"zh": {
		"Chat": "互动消息",
    "Chat now": "暂无互动消息",
    "Type a message": "说点什么...",
    "Send": "发送"
	}
}
</i18n>

