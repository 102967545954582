<template lang="pug">
  item-card.card-container(:title="$t('Participants')")
      div.watch-list-content
        div.online-member-box
          span.online-member-title 当前在线 {{onlineCount}}人
        div.search-member-box
          el-input.search-member-input(
            v-model="nickNameKeyWord"
            :placeholder="$t('Search')+$t('User')"
            @input="handleSearch"
            @keyup.enter.native="handleSearch"
            @change="handleSearch"
            size="small"
            clearable
          )
          el-checkbox.all-mute-checkbox(
            v-model="isMuteAllMembers"
            @change="muteAllMembers"
          ) 全体禁言
        div.watch-list-member
          div.user-item(v-for="member, index in memberList" v-if="handleSearch(member)" )
              //- 显示用户头像和昵称信息
              div.user-info
                img.user-avatar(:src="getUserAvatar(member)")
                el-tooltip(
                  class="item"
                  :content="getUserNick(member)"
                  placement="bottom"
                  visible-arrow=false
                )
                  span.user-name {{getUserNick(member)}}
              //- 显示用户是否被禁言状态
              div.user-state(v-if="member.userID !== userInfo.userId")
                span.icon(@click="toggleMuteStatus(member)")
                  el-tooltip(class="item" :content="$t('Mute')" placement="top")
                    svg-icon(icon-name="info" v-show="!member.isMuted")
                  el-tooltip(class="item" :content="$t('Unmute')" placement="top")
                    svg-icon(icon-name="info-forbidden" v-show="member.isMuted")
</template>

<script>
// import img from 'assets/img/avatar.png';
import itemCard from '@/components/common/item-card';
// import tim from '@/components/mixin/tim';
import { mapState } from 'vuex';
export default {
  name: 'compParticipants',
  // mixins: [tim],
  components: {
    itemCard,
  },
  data() {
    return {
      img: 'https://wanwuxiu.oss-cn-qingdao.aliyuncs.com/attach/2024/05/e5a60202405071549396022.png',
      nickNameKeyWord: '',
      isMuteAllMembers: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      memberList: 'memberList',
      onlineCount: 'onlineCount',
    }),
  },
  methods: {
    // 获取用户头像
    getUserAvatar(userInfo) {
      if (!userInfo.avatar.includes('http')) {
        return this.img;
      }
      return userInfo.avatar ? userInfo.avatar : this.img;
    },
    // 获取用户昵称
    getUserNick(userInfo) {
      return userInfo.nick ? userInfo.nick : userInfo.userID;
    },
    // 切换禁言状态
    toggleMuteStatus(userInfo) {
      const muteTime = userInfo.isMuted ? 0 : 7 * 24 * 60 * 60;
      this.$eventBus.$emit('tim:setGroupMemberMuteTime', {
        userID: userInfo.userID,
        muteTime,
      });
    },
    handleSearch(member) {
      console.log('输入的搜索用户昵称关键字是：', this.nickNameKeyWord);
      if (!this.nickNameKeyWord) {
        return true;
      }
      if (typeof member.nick === 'undefined' || typeof member.userID === 'undefined') {
        return false;
      }
      // 过滤出匹配搜索关键词的成员 如果昵称或者用户id包含搜索关键字，则返回true
      return !!(member.nick.includes(this.nickNameKeyWord) || member.userID.includes(this.nickNameKeyWord));
    },
    muteAllMembers() {
      this.$eventBus.$emit('tim:setGroupMuteAllMembers');
    },
    updataIsMuteAllMembers(groupProfile) {
      console.log('收到的事件传递过来的groupProfile.muteAllMembers', groupProfile.groupProfile.muteAllMembers);
      if (groupProfile.groupProfile.muteAllMembers == 'On') {
        this.isMuteAllMembers = true;
      } else {
        this.isMuteAllMembers = false;
      }
    },
  },
  mounted() {
    this.$eventBus.$on('tim:getGroupProfileReady', (groupProfile) => {
      this.updataIsMuteAllMembers(groupProfile);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('tim:getGroupProfileReady', this.updataIsMuteAllMembers);
  },
};
</script>

<style lang="stylus" scoped>
  .card-container
    width 100%
    max-height 26%
    display flex
    flex-direction column
  .watch-list-content
    flex-grow 1
    width 100%
    margin 10px 0
    height 40%
    border-radius 10px
    padding 0 14px;
  .search-member-box
    display flex
    flex-direction row
    width 100%
    margin 10px 0
    justify-content space-between
    align-items center
  .search-member-input
    width 70%
    margin-right 10px
  .watch-list-member
    color $fontColor
    width 100%
    height 100%
    display flex
    flex-direction column
    overflow auto
    font-size 14px
    .user-item
      display flex
      flex-direction row
      margin 0 0 8px 0
      justify-content space-between
      align-items center
      width 100%
      .user-info
        display flex
        .user-avatar
          min-width 23px
          width 23px
          height 23px
          border-radius 50%
          margin 0 10px 0 0
          display inline-block
          vertical-align middle
          object-fit cover
        .user-name
          display inline-block
          max-width 210px
          height 24px
          line-height 24px
          text-align left
          white-space nowrap
          width 100%
          overflow hidden
          text-overflow ellipsis
      .user-state
        height 20px
        .icon
          display inline-block
          width 21px
          height 21px
          cursor pointer
</style>

<style>
/* 美化整个滚动条 */
::-webkit-scrollbar {
  width: 5px; /* 设置滚动条的宽度 */
  background-color: #555; /* 滚动条的背景色 */
}
/* 美化滚动条轨道 */
::-webkit-scrollbar-track {
  background: #333; /* 轨道的背景色 */
}
/* 美化滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #555; /* 滑块的背景色 */
}
/* 当滑块悬停或活动时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 悬停或活动状态下的背景色 */
}
</style>

<i18n>
{
	"en": {
		"Participants": "Participants",
    "Mute": "Mute",
    "Unmute": "Unmute",
    "Search": "Search",
    "User": "User"
	},
	"zh": {
		"Participants": "在线观众",
    "Mute": "禁言",
    "Unmute": "取消禁言",
    "Search": "搜索",
    "User": "用户"
	}
}
</i18n>
